
import BDatepicker from 'buetify/lib/components/form/datepicker/BDatepicker';
import { defineComponent, shallowRef } from 'vue';

export default defineComponent({
	name: 'datepicker-inline-example',
	components: {
		BDatepicker
	},
	setup() {
		const selectedDate = shallowRef(new Date());

		return {
			selectedDate
		};
	}
});
