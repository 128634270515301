
import BDatepicker from 'buetify/lib/components/form/datepicker/BDatepicker';
import BField from 'buetify/lib/components/form/field';
import { defineComponent, shallowRef } from 'vue';

export default defineComponent({
	name: 'datepicker-simple-example',
	components: {
		BField,
		BDatepicker
	},
	setup() {
		const selectedDates = shallowRef([]);

		return {
			selectedDates
		};
	}
});
