
import BHorizontalDivider from 'buetify/lib/components/layout/divider/BHorizontalDivider';
import { defineComponent } from 'vue';
import ApiView from '../../../../../components/apiView/ApiView.vue';
import { ExampleView } from '../../../../../components/exampleView';
import { apis } from './apis';
import EventsExample from './examples/EventsExample.vue';
import EventsExampleCode from '!!raw-loader!./examples/EventsExample.vue';
import MultipleDatesExample from './examples/MultipleDatesExample.vue';
import MultipleDatesExampleCode from '!!raw-loader!./examples/MultipleDatesExample.vue';
import SimpleExample from './examples/SimpleExample.vue';
import SimpleCode from '!!raw-loader!./examples/SimpleExample.vue';
import InlineExample from './examples/InlineExample.vue';
import InlineExampleCode from '!!raw-loader!./examples/InlineExample.vue';
import FooterExample from './examples/FooterExample.vue';
import FooterExampleCode from '!!raw-loader!./examples/FooterExample.vue';

export default defineComponent({
	name: 'datepicker-documentation',
	components: {
		ExampleView,
		ApiView,
		SimpleExample,
		InlineExample,
		FooterExample,
		MultipleDatesExample,
		BHorizontalDivider,
		EventsExample
	},
	setup() {
		return {
			apis,
			EventsExampleCode,
			SimpleCode,
			InlineExampleCode,
			FooterExampleCode,
			MultipleDatesExampleCode
		};
	}
});
