
import BButton from 'buetify/lib/components/button/BButton';
import BDatepicker from 'buetify/lib/components/form/datepicker/BDatepicker';
import BField from 'buetify/lib/components/form/field';
import { defineComponent, shallowRef } from 'vue';

export default defineComponent({
	name: 'datepicker-footer-example',
	components: {
		BDatepicker,
		BButton,
		BField
	},
	setup() {
		const selectedDate = shallowRef<Date | null>(null);

		function setToday() {
			selectedDate.value = new Date();
		}

		function clear() {
			selectedDate.value = null;
		}

		return {
			setToday,
			clear,
			selectedDate
		};
	}
});
